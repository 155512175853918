@import '../../../../scss/theme-bootstrap';

///
/// @file components/prouducts/_product_grid.scss
///
/// \brief Styles for multi-product grids
///
///

.block-template-product-grid-v1 {
  .basic-carousel-formatter {
    .carousel-dots {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
}

.mpp-sort__container {
  display: none;
  position: absolute;
  right: 12px;
  top: -38px; // line height of select element, pull up out of grid
  @include breakpoint($landscape-up) {
    display: inline;
  }
  .select-box {
    min-width: 178px;
    &__label,
    &__options {
      min-width: 212px;
    }
  }
}

.product-grid-wrapper {
  max-width: $mpp-max-width;
  clear: both;
  margin: 0 auto;
  position: relative;
}

.product-grid {
  width: 100%;
  position: relative;
  //overflow: hidden;
  &__content {
    @include pie-clearfix;
    //max-width: $max-width-large;
    margin: 0 auto;
  }
  &--small-cols-2,
  &--small-cols-3 {
    .product-grid__item {
      float: left;
    }
  }
  &--small-cols-2 .product-grid__item {
    width: 50%;
  }
  &--small-cols-3 .product-grid__item {
    width: 33.333%;
  }
  &__item {
    width: 100%;
  }
  @include breakpoint($landscape-up) {
    // top: 30px;
    top: 32px;
    &.product-grid--carousel {
      top: 0;
    }
    &__item {
      float: left;
      width: 33.333%;
    }
    &--medium-cols-1 .product-grid__item {
      width: auto;
      float: none;
    }
    &--medium-cols-2 .product-grid__item {
      width: 50%;
    }
    &--medium-cols-3 .product-grid__item {
      width: 33.333%;
    }
    &--medium-cols-4 .product-grid__item {
      width: 25%;
    }
  }
  // inline tout padding to match product brief
  &__tout {
    padding: 0 5px 0 5px;
    @include breakpoint($landscape-up) {
      padding: 0 12px 0 12px;
    }
  }
}
